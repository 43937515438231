@import "../../_sass//typography";
@import "../../_sass/component";
@import "../../_sass/variables";
@import "../../_sass/mixins";

.sub-cancel-container {
  background-color: #222;
  color: #FFF;
}

.contact-us {
  @include flexCenter;
  color: $color-black;
  background-color: $color-white;
  min-height: 75vh;
  text-align: center;
  padding: 2rem;
}

.contact-form-container {
  background-color: #F1F1F1;
  color: $color-black;
  padding: 1rem;
}
