@import "../../_sass/mixins";
@import "../../_sass/typography";
@import "../../_sass/variables";
@import "../../_sass/component";

.header {
  @include flexSpaceBetween;
  padding: 1.2rem;
  a {
    @include flexCenter;
  }
}
.menu-list {
  background: $color-white !important;
  box-shadow: $light-shadow !important;
  color: $color-black !important;
  padding: 2px;
  button {
    font-family: PTSerif;
    background-color: $color-white;
    color: $color-black;
    font-size: 1.5rem;
    padding: 1.2rem 2rem;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &:hover {
      background-color: #F1F1F1;
    }
  }
}