@import "../../_sass//component";
@import "../../_sass/typography";
@import "../../_sass/variables";
@import "../../_sass/mixins";

.login-page {
  color: $color-black;
  background-color: $color-white;
  min-height: 90vh;
}
.login-form-container {
  @include flexSpaceAround;
  min-height: 90vh;
  padding-left: 2rem;
  padding-right: 2rem;
  .login-left-side,
  .login-right-side {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.remember-me-checkbox {
  display: flex;
  gap: 1rem;
  width: 40% !important;
  p {
    font-size: $small-font-size;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (max-width: 1279px) {
  .login-form-container {
    display: flex;
    flex-direction: column;
    .login-left-side {
      justify-content: flex-end;
    }
    .login-right-side{
      justify-content: flex-start;
      margin-top: 1rem;
    }
  }
}