@import "../../../../_sass/mixins";
.mobile-book-page-item {
  @include flexCenter;
  background-size: contain;
  width: 72px;
  height: 84px;
  background-color: #AAA;
  border-radius: 10px;
  @media (max-width: 475px) {
    width: 42px;
    height: 56px;
  }
  &:hover {
    filter: brightness(1.25);
  }
  p {
    margin-top: 6rem;
    @media (max-width: 475px) {
      margin-top: 5rem;
    }
  }
}