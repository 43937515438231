@import "../../_sass/mixins";

.subscription-page-con {
  background-color: #111;
  color: #FFF;
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 900px;
}

.subscription-card {
  @include flexCenter;
  background-color: #222;
  color: #FFF;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #444;
  text-align: center;
  padding: 3rem;
  &:hover {
    border: 1px solid #EEE;
  }
}