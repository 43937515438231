@import "../../_sass/mixins";
@import "../../_sass/variables";

.booklist-container {
  @include flexCenter;
}
.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  color: #999;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  outline: none;
  &-left {
    left: 50px;
  }
  &-right {
    right: 0;
  }
  &:hover {
    color:#AAA;
    background-color:#444;
  }
  @media (max-width: 1023px) {
    display: none;
  }
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: 84%;
  margin-left: auto;
  margin-right: auto;          
  padding: 10px;
  touch-action: auto;
  @media (max-width: 1023px) {
    overflow-x: scroll;
  }
}

.carousel-book {
  width: 180px;
  height: 285px;
  border-radius: 5px;
  color: $color-white;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.1);
    transition: transform 0.25s ease;
  }

  &-no-image {
    @include flexCenter;
    padding-left: 5px;
    padding-right: 5px;
    color: $color-white;
    background-color: #1F1F1F;
  }
}