@import "../../_sass/variables";
.icon-round {
  width: 5rem;
  border-radius: 50%;
  box-shadow: $light-shadow;
  cursor: pointer;
  filter: brightness(100%);
  &:hover {
    filter: brightness(120%);
    box-shadow: $default-shadow;
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: $light-shadow;
    transform: translateY(1px);
  }
}