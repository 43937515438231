@import "./variables";
.app-heading {
  font-size: 5rem;
  line-height: 3.5rem;
  color: $color-black;
  font-weight: 400;
}
.section-heading {
  line-height: 36px;
  font-weight: 600;
  font-size: 3.5rem;
  text-align: center;
  &-white {
    color: $color-white;
  }
  &-black {
    color: $color-black;
  }
}
.large-text {
  font-size: 2.2rem !important;
  line-height: 3rem !important;
}
.medium-text {
  font-size: 1.8rem !important;
  line-height: 2.6rem !important;
}
.small-text {
  font-size: 1.6rem !important;
  line-height: 2rem !important;
}
.x-small-text {
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}
.x-small-text,
.small-text, 
.medium-text,
.large-text {
  &-grey {
    color: $light-grey-text;
  }
  &-black {
    color: $color-black;
  }
  &-white {
    color: $color-white;
  }
  &-blue {
    color: $light-blue !important;
  }
  &-bold {
    font-weight: 700;
  }
  &-error {
    color: $error-red;
  }
  &-link,
  &-link:visited,
  &-link:link {
    color: #DDD !important;
    &:hover {
      color: $color-white !important;
    }
    &:active {
      color: $color-white;
    }
  }
  &-footer-links {
    color: #CCC !important;
    &:hover {
      color: $color-white !important;
    }
  }
}