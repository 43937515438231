.desktop-book-view {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: left;
  min-height: 650px;
  margin-top: 20px;
  margin-bottom: 3rem;
  width: 1200px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}