@import "../../_sass/mixins";
@import "../../_sass/variables";
@import "../../_sass/typography";

.app-footer {
  @include flexCenter;
  background-color: $color-black;
  color: #939596;
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

#footer-links {
  @include flexHorizontal;
  margin-bottom: 1rem;
}

@media (max-width: 800px) {
  #footer-links {
    flex-direction: column;
  }
}