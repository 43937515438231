// Colors 
$color-white: #FFF;
$color-black: #000;
$light-grey-text: #747474;
$border-blue: #0F52BA;
$error-red: #FF1111;
$light-blue: #1890ff;
$dark-grey: #444;
$dark-night: #333;

// Box Shadow
$light-shadow: 0 .5rem 1rem rgba(0,0,0,0.2);
$default-shadow: 0 1rem 2rem rgba(0,0,0,0.2);

// font sizes 
$medium-font-size: 2rem;
$small-font-size: 1.6rem;
