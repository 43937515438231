@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@import "./_sass//variables";
@font-face {
  font-family: 'Garamond';
  src: local('Garamond'), url(./assets/fonts/Garamond.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'PTSerif';
  src: local('PTSerif'), url(./assets/fonts/PTSerif.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'PTSerifBold';
  src: local('PTSerifBold'), url(./assets/fonts/PTSerifBold.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
:root {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // setting default font to 10px;
  font-family: PTSerif, sans-serif, Garamond;
  font-size: 62.5%;
  box-sizing: border-box;
  background-color: #222;
}
/* new css */
sup {
  color: #FF0000;
  cursor: pointer;
  text-decoration: underline;
}
code {
  font-family: 'PTSerif';
}
.onSelectHighlighted:hover{
  background-color: #FF0000;
  color: rgb(245, 191, 83);
}
.onSelectHighlighted:target{
  background-color: cadetblue;
}
input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #AAA;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #AAA;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #AAA;
}
.yellowTheme {
  background-color: #F9E991;
}
/*
.yellowTheme {
  background-color: #FFD700;
}
*/
.yellowTheme:hover {
  color: #FF6347;
}
/*
.blueTheme {
  background-color: #1E90FF;
}
*/
.blueTheme {
  background-color: #A5C7F7;
}
.blueTheme:hover {
  color: #b21800;
}
.greenTheme {
  background-color: #D1F3C5;
}
.greenTheme:hover {
  color: #FF6347;
}
.redTheme {
  background-color: #FFB6C7;
}
.redTheme:hover {
  color: #a41900;
}
.pagination {
  font-size: $small-font-size;
}

@media (max-width: 700px) {
  :root {
    font-size: 50%;
  }
}

@media (max-width: 300px) {
    :root {
      font-size: 30%;
  }
}
