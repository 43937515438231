@import "../../../_sass/mixins";
.mobile-book-page-item {
  @include flexCenter;
  position: relative;
  background-size: contain;
  width: 72px;
  height: 84px;
  background-color: #AAA;
  border-radius: 10px;
  &:hover {
    filter: brightness(1.25);
  }
  p {
    margin-top: 6rem;
  }
}