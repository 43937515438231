@import "../../../_sass/variables";
@import "../../../_sass/mixins";

.small-logo {
  display: flex;
  justify-content: center;
  height: fit-content;
  p {
    display: inline-block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  hr {
    display: inline;
    width: 2rem;
    height: 3px;
    line-height: 1.8rem;
    ;
  }
}