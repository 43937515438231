@import "./variables";
@import "./mixins";

.card {
  @include flexCenter;
  width: 350px;
  height: 650px;
  padding: '3rem';
  &-black {
    color: "#FFF";
    background-color: #111;
    border: solid 1px $light-grey-text;
  }
  &:hover {
    border-color: $color-white;
  }
}
@media (max-width: 675px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .card {
    height: 450px
  }
}
.link-btn {
  height: 5rem  !important;
  @include flexCenter;
  padding: 1.5rem 4rem !important;
  font-size:  1.5rem !important;
  line-height: 1.8rem !important;
  font-weight: 700 !important;
  border-radius: 2px !important;
  letter-spacing: 2px !important;
  text-transform: uppercase;
  cursor: pointer;
  &:link,
  &:visited {
    text-decoration: none;
    outline: transparent;
  } 
  &-medium {
    width: 20rem;
  }
  &-small {
    font-size:  1.3rem !important;
    line-height: 1.6rem !important;
    padding: 1.5rem !important;
    height: 3.5rem !important;
    min-width: 14rem !important;
  }
  &-grey {
    background-color: #CCC;
    color:$color-black;
    &:hover {
      background-color: $color-white;
      color:$color-black;
    }
  }
  &-dark-grey {
    background-color: $dark-grey !important;
    color: $color-white !important;
    &:hover {
      background-color: $dark-grey;
      color: $color-white;
    }
  }
  &-night {
    background-color: $dark-night !important;
    color: $color-white !important;
    &:hover {
      background-color: $dark-grey !important;
      color: $color-white !important;
    }
  }
  &-black {
    background-color: $color-black !important;
    color: $color-white !important;
    box-shadow: $light-shadow;
    &:hover {
      background-color: $color-black;
      color: $color-white;
      box-shadow: $default-shadow;
    }
    &:active{
      box-shadow: $light-shadow;
    }
  }
  &-white {
    background-color: $color-white !important;
    color:$color-black !important;
    &:hover {
      background-color: $color-white;
      color:$color-black;
      box-shadow: $light-shadow;
    }
  }
  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}
.round-btn {
  height: 4rem !important;
  @include flexCenter;
  padding: 1.5rem 2rem !important;
  font-size:  1.4rem !important;
  line-height: 1.8rem !important;
  font-weight: 700 !important;
  border-radius: 25px !important;
  letter-spacing: 2px !important;
  background-color: #333 !important;
  color: #FFF !important;
  box-shadow: $light-shadow;
  &:hover {
    background-color: $dark-grey !important;
    color: $color-white !important;
    box-shadow: $default-shadow;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}
.input-custom {
  width: 100%;
  background-color: $color-white;
  transition: border 400ms ease;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1.3px;
  padding: .5rem 1rem;
  border-radius: 5px;
  font-size: $small-font-size;
  border: solid 2px $light-grey-text;
  &:hover,
  &:focus,
  &:active {
    border: solid 2px $border-blue;
    outline: transparent;
    outline-width: 0 !important;
  }
}

.hamburger-menu {
  background-color: $color-white;
  span{
    @include flexCenter;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    hr {
      color: $color-black;
      display:  inline-block;
      width: 3rem;
      margin-top: .3rem;
      margin-bottom: .3rem;
      background-color: $color-black;
      border-color: $color-black;
      opacity: 1;
      height: 3px;
    }
  }
  &:hover {
    span {
      box-shadow: $default-shadow;
    }
  }
}

.search-image {
  width: 2.8rem;
  height: 2.8rem;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 4px;
  &:focus {
    outline:0 !important;
    border: 0 !important;
  }
  @media (max-width: 48em) {
    width: 26px;
  }
}
// .input-custom:-webkit-autofill,
// .input-custom:-webkit-autofill:hover, 
// .input-custom:-webkit-autofill:focus, 
// .input-custom:-webkit-autofill:active{
//   -webkit-box-shadow: 0 0 0 30px white inset !important;
// }
// input:-webkit-autofill{
//   -webkit-text-fill-color: black !important;
// }