@import "../../../../_sass/typography";
@import "../../../../_sass/component";
@import "../../../../_sass/variables";
@import "../../../../_sass/mixins";

.signup-section {
  @include flexCenter;
  background-color: $color-black;
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
  &-cards {
    @include flexHorizontal;
    article {
      padding: 2rem;
      a {
        margin-top: 2rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .signup-section {
    &-cards {
      display: block;
      article {
        &:last-child {
          margin-top: 2rem;
        }
      }
    }
  }
}