@import "../../../../_sass/mixins";
@import "../../../../_sass/typography";
@import "../../../../_sass/variables";

.main-body {
  background-color: $color-white;
  height: 75vh;
  min-height: 550px;
  position: relative;
  &-text {
    height: 100%;
    max-width: 95%;
    margin: auto;
    @include flexCenter;
    p {
      text-align: center;
      &:nth-child(2){
        margin-top: 4rem;
      }
    }
  }
  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0); 
  }
}