@import "../../../../_sass/typography";
@import "../../../../_sass/mixins";
.howitworks-container {
  background-color: black;
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
  article {
    @include flexHorizontal;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
  }
}
@media (max-width: 1500px) {
  .howitworks-container {
    article {
      width: 100%;
    }    
  }
}

@media (max-width: 991px) {
  .howitworks-container {
    article {
      display: block;
    }    
  }
}