@mixin flexCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@mixin flexLeftCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items:start;
}

@mixin flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flexSpaceAround {
  display: flex;
  justify-content: space-around;
}


@mixin flexHorizontal {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

@mixin darkbackGround {
  background-color: #111;
  color: #FFF;
}

