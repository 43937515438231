@import "../../../_sass/mixins";
.book-page-item {
  @include flexCenter;
  position: relative;
  background-size: contain;
  width: 108px;
  height: 127px;
  background-color: #AAA;
  border-radius: 10px;
  &:hover {
    filter: brightness(1.25);
  }
  p {
    margin-top: 6rem;
  }
}