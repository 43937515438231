@import "../../_sass/variables";
.btn {
  font-family: PTSerif sans-serif Garamond;
  border-radius: 0;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  &-medium {
    min-width: 14rem;
    padding: 1rem 2rem;
    height: 4.4rem;
    font-size: 1.8rem;
  }
  &-small {
    min-width: 10rem;
    height: 3.5rem;
    font-size: 1.4rem;
  }
  &-black {
    background-color:#000;
    color: $color-white;
    box-shadow: $light-shadow;
    &:hover {
      background-color: #222;
      color: $color-white;
    }
  }
  &-white {
    background-color:$color-white;
    color: #000;
    &:hover {
      background-color: #F1F1F1;
    }
  }
  &-grey {
    background-color:$color-white;
    color: #000;
    &:hover {
      background-color: #F1F1F1;
    }
  }
  &-night {
    background-color: #333;
    color: $color-white;
    &:hover {
      background-color: $dark-grey;
      color: $color-white; 
    }
  }
  &:hover {
    transition: all .2s;
    transform: translateY(-1px);
    box-shadow: $default-shadow;
  }
  &:active {
    transform: translateY(1px);
    box-shadow: $light-shadow;
  }
}

.spinner-off {
  display: none !important;
}

.spinner-on {
  display: inline-block !important;
}