@import "../../../_sass/variables";

.main-logo,
.main-logo:link,
.main-logo:visited {
  font-family: sans-serif, "PT Serif";
  font-size: 1.7rem;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: 350px) {
    font-size: 1.3rem;
  }
}

.main-logo {
  &-dark {
    color: $color-black !important;
  }
  &-light {
    color: #CCC !important;
    &:hover {
      color: $color-white !important;
    }
  }
}